<template>
  <section class='mt-8 px-8' style='page-break-after: always'>
    <div class='container'>
      <h2 class='text-center text-xl'>
        <button
          class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500 text-blue-500'
          @click='calendarData(-1)'>&lt;&lt;
        </button>
        <button
          class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500'
          @click='getLedgerSchedules'> {{ year }}년 {{ month }}월 </button>
        <button
          class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500 text-blue-500'
          @click='calendarData(1)'>&gt;&gt;
        </button>
      </h2>
      <table class='w-full table-fixed mb-10'>
        <thead>
          <th class='px-2 py-2 text-center border-r border-l border-b border-t w-32'>일요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-64'>월요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-64'>화요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-64'>수요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-64'>목요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-64'>금요일</th>
          <th class='px-2 py-2 text-center border-r border-b border-t w-32'>토요일</th>
        </thead>
        <tbody>
          <tr v-for='(schedule, idx) in schedules' :key='idx' class='text-center uppercase tracking-wide border-b border-r border-l'>
            <td
              v-for='(descRow, secondIdx) in schedule' :key='secondIdx' class='px-2 py-2 text-center border-r align-top'>
              <div @dblclick='insertPrompt(descRow)' class='text-right'> {{ descRow.c_date }} </div>
              <div @dblclick='deleteConfirm(descRow, thirdIdx)'
                class='text-left' v-for='(description, thirdIdx) in splitData(descRow.descriptions)' :key='thirdIdx'>
                <span :class='getColor(description, 0)'> {{ getText(description, 0) }} </span>
                <span :class='getColor(description, 1)'> {{ getText(description, 1) }} </span>
                <span> {{ getText(description, 2) }} </span>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr><td colspan='100' class='text-right py-4'>
            &#8251; 삭제:내용 더블클릭, 추가:일자 더블클릭
          </td></tr>
        </tfoot>
      </table>
    </div>
    <div class='container'>
      <h2 class='text-left text-xl'>기간별 수령/납입 금액</h2>
      <div class='flex flex-row my-6'>
        <el-date-picker
          v-model='rangeDate'
          style='width: 20rem;'
          type='daterange'
          placeholder='결제일자'
          :default-value='rangeDate'
          value-format='yyyy-MM-dd'
          @change='getSettlementValue'
          range-separator='~'
          start-placeholder='Start Date'
          end-placeholder='End Date'
          align='right'
          unlink-panels>
        </el-date-picker>
        <button
          class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500'
          @click='getSettlementValue'>
          조회
       </button>
       <label class='inline-block py-3'>
         <input type='checkbox' class='form-checkbox' v-model='detail'>
         상세
       </label>
      </div>
      <table>
        <tbody v-if='detail'>
          <tr>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' rowspan='2'>펀드</td>
            <td class='px-2 py-2 text-center border-r border-b border-t' rowspan='2'>총금액</td>
            <td :colspan='sellColspan' class='px-2 py-2 border-r border-l border-b border-t text-center bg-red-100'>수령</td>
            <td :colspan='buyColspan' class='px-2 py-2 border-r border-l border-b border-t text-center bg-blue-100'>납입</td>
          </tr>
          <tr>
            <td class='px-2 py-2 text-left border-r border-b' v-for='(sellStock, index) in sellStocks' :key='index'> {{ sellStock }} </td>
            <td class='text-center border-r border-b'>합계</td>
            <td class='px-2 py-2 text-left border-r border-b' v-for='(buyStock, index) in buyStocks' :key='index+100'> {{ buyStock }} </td>
            <td class='text-center border-b border-r'>합계</td>
          <tr>
          <tr v-for='(order, index) in orders' :key='index'>
            <td class='px-2 py-2 text-left border-l border-r'>{{ order.fund_name }}</td>
            <td class='px-2 py-2 text-right border-r'> {{ toNumber(order.net_value) }}</td>
            <td v-for='(sellValue, index) in sellValues(order)' :key='index' class='px-2 py-2 text-right border-r'>
              {{ toNumber(sellValue) }}
            </td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.sum_sell_value) }}</td>
            <td v-for='(buyValue, index) in buyValues(order)' :key='index+100' class='px-2 py-2 text-right border-r'>
              {{ toNumber(buyValue) }}
            </td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.sum_buy_value) }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class='px-2 py-2 text-center border-l border-r border-b border-t' rowspan='2'>펀드</td>
            <td class='px-2 py-2 text-center border-r border-b border-t' rowspan='2'>총금액</td>
            <td class='px-2 py-2 border-r border-l border-b border-t text-center bg-red-100'>수령</td>
            <td class='px-2 py-2 border-r border-l border-b border-t text-center bg-blue-100'>납입</td>
          </tr>
          <tr>
            <td class='text-center border-r border-b'>합계</td>
            <td class='text-center border-b border-r'>합계</td>
          </tr>
          <tr v-for='(order, index) in orders' :key='index'>
            <td class='px-2 py-2 text-left border-l border-r'>{{ order.fund_name }}</td>
            <td class='px-2 py-2 text-right border-r'> {{ toNumber(order.net_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.sum_sell_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.sum_buy_value) }}</td>
          </tr>
        </tbody>
      </table>
    </div> 
  </section>
</template>

<script>
import SchedulesApi                           from '@/api/v1/schedules'
import OrdersApi                              from '@/api/v1/orders'
import numbro from 'numbro'
import dayjs from 'dayjs'

export default {
  name: 'LedgerCalendar',
  data() {
    return {
      currentYear: 0,
      currentMonth: 0,
      year: 0,
      month: 0,
      today: 0,
      fromDate: '',
      toDate: '',
      schedules: [],
      valuePerDate: [],
      rangeDate: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      detail: true,
      orders: [],
      sellStocks: [],
      buyStocks: [],
      sellCount: 0,
      buyCount: 0,
      newSchedule: {
        investment_id: 0,
        schedule_type: '원장',
        schedule_date: '',
        start_time: '',
        end_time: '',
        location: '',
        description: '',
        user_ids: []
      },
    };
  },
  computed: {
    sellColspan () {
      return this.sellCount + 1
    },
    buyColspan () {
      return this.buyCount + 1
    },
  },
  methods: {
    initialDate () {
      var date = new Date();
      this.currentYear = date.getFullYear(); // 이하 현재 년, 월 가지고 있기
      this.currentMonth = date.getMonth() + 1;
      this.year = this.currentYear;
      this.month = this.currentMonth;
      this.today = date.getDate(); // 오늘 날짜
      this.calendarData(0);
    },
    calendarData (arg) { // 인자를 추가
      if (arg < 0) { // -1이 들어오면 지난 달 달력으로 이동
        this.month -= 1;
      } else if (arg === 1) { // 1이 들어오면 다음 달 달력으로 이동
        this.month += 1;
      }
      if (this.month === 0) { // 작년 12월
        this.year -= 1;
        this.month = 12;
      } else if (this.month > 12) { // 내년 1월
        this.year += 1;
        this.month = 1;
      }

      var currentFirstDate = new Date(this.year, this.month - 1, 1)
      var currentLastDate = new Date(this.year, this.month, 0)
      var fDay = currentFirstDate.getDay()
      var tDay = currentLastDate.getDay()
      var fDate = new Date(this.year, this.month - 1, -fDay + 1)
      var tDate = new Date(this.year, this.month, 7 - tDay - 1)

      this.fromDate = fDate.getFullYear() + '-' + (fDate.getMonth() + 1) + '-' + fDate.getDate() 
      this.toDate = tDate.getFullYear() + '-' + (tDate.getMonth() + 1) + '-' + tDate.getDate() 

      this.getLedgerSchedules ()
    },
    getLedgerSchedules () {
      this.schedules = []
      SchedulesApi.getLedgerSchedules({from_date: this.fromDate, to_date: this.toDate}).then(resp => {
        for (var ii = 0; ii < 7; ii++) {
          this.schedules.push(resp.filter((res => res.row_index === ii)))
        }
      })
    },
    splitData (string) {
      if (string)
        return string.split(',')
      else
        return ''
    },
    getText (string, index) {
      var text
      
      if (string) {
        text = string.split('|')
        if (text[index])
          return text[index]
        else
          return ''
      } else {
        return ''
      }
    },
    getColor (string, index) {
      var text

      if (string) {
        text = string.split('|')
        if (text[index] === '[매수]' || text[index] === '[수령]') {
          return 'text-red-500'
        } else if (text[index] === '[매도]' || text[index] === '[납입]'){
          return 'text-blue-500'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getDescText (string) {
      var descText = string.split('|')

      return descText.slice(2, descText.length)
    },
    getSettlementValue() {
      this.orders = []
      OrdersApi.getSummaryOrders({from_date: this.rangeDate[0], to_date: this.rangeDate[1]}).then(resp => {
        this.orders = resp
        if (this.orders.length > 0 && this.orders[0].sell_stock_names && this.orders[0].sell_stock_names.length > 0) {
          this.sellStocks = this.orders[0].sell_stock_names.split(',')
          this.sellCount = this.sellStocks.length
        }
        if (this.orders.length > 0 && this.orders[0].buy_stock_names && this.orders[0].buy_stock_names.length > 0) {
          this.buyStocks = this.orders[0].buy_stock_names.split(',')
          this.buyCount = this.buyStocks.length
        }
      })
    },
    sellValues(order) {
      if (!order || !order.sell_values || order.sell_values.length === 0)
        return []

      return order.sell_values.split(',')
    },
    buyValues(order) {
      if (!order || !order.buy_values || order.buy_values.length === 0)
        return []

      return order.buy_values.split(',')
    },
    toNumber (number) {
      if (number) {
        return numbro(number).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    insertPrompt (descRow) {
      var description = prompt('일정내용을 입력하세요')
      if (description) {
        this.newSchedule.description = description.replace(/\]/g, ']|')
        this.newSchedule.schedule_date = descRow.schedule_date
        SchedulesApi.postSchedule(this.newSchedule).then((resp) => {
          if (resp && resp.id > 0) {
            alert('일정추가 완료')
            this.getLedgerSchedules ()
          } else {
            alert('일정추가 실패')
          }
        })
      } else {
        alert('일정입력이 취소 되었습니다.')
      }
    },
    deleteConfirm (descRow, index) {
      if (!descRow || !descRow.descriptions) return

      var descriptions = this.splitData(descRow.descriptions, ',')
      if (confirm(descriptions[index].replace(/\|/g, ' ') + ' 를 삭제하시겠습니까?')) {
        var schedule_ids = this.splitData(descRow.schedule_ids, ',')
        SchedulesApi.deleteSchedule(schedule_ids[index]).then((resp) => {
          if (resp && !resp.active) {
            alert('일정삭제 완료')
            this.getLedgerSchedules ()
          } else {
            alert('일정삭제 실패')
          }
        })
      } else {
        alert('일정삭제가 취소되었습니다.')
      }
    }
  },
  mounted () {
    this.initialDate()
    this.getSettlementValue()
  },
};
</script>
